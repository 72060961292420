<template>
  <div>
    <div :class="twMerge('flex flex-col gap-2 items-center', props.appendClass)">
      <span
          v-if="props.isTitle && props.type !== 'report'"
          :class="twMerge([
              'text-natural-grey dark:text-natural-light-grey font-medium'
          ])"
      >Page <span class="font-semibold">{{ props.activePageNumber }}</span> of <span class="font-semibold">{{ totalPages }}</span>
      </span>
      <div class="flex items-center">
        <div :class="twMerge(
          'inline-flex gap-1',
          '*:cursor-pointer *:py-2 *:rounded-md',
          '*:text-natural-grey *:font-medium *:dark:text-natural-light-grey',
          props.outlined && '*:border *:border-natural-light-grey *:dark:border-natural-grey',
          'hover:*:bg-natural-soft-white hover:*:dark:bg-natural-dark-grey'
      )">
          <TsButton
              class="flex items-center gap-1.5"
              :class="twMerge([
                'px-3',
                styleClasses.disabled
            ])"
              v-if="props.type !== 'simple'"
              @click="handleFirst"
              :disabled="activePageNumber === 1"
          >
            <Icon v-if="!props.textOnly" name="iconoir:fast-arrow-left"/>
            <span v-if="props.withText || props.textOnly">First</span>
          </TsButton>
          <TsButton
              class="flex items-center gap-1.5"
              :class="twMerge([
                'px-3',
                styleClasses.disabled
            ])"
              @click="handlePrev"
              :disabled="activePageNumber === 1"
          >
            <Icon v-if="!props.textOnly" name="iconoir:nav-arrow-left"/>
            <span v-if="props.withText || props.textOnly">Previous</span>
          </TsButton>
          <template v-for="page in visiblePaginations()"
                    :key="page" >
            <span v-if="page==='/'" class="!bg-transparent !cursor-none">{{ page }}</span>
            <span v-else-if="page==='...'" class="!bg-transparent !cursor-none px-3 !border-0">{{ page }}</span>
            <TsButton
                v-else-if="props.type !== 'report'"
                :append-class="twMerge([
                    'w-11 max-w-11 text-center border-0 focus:ring-0 bg-transparent hover:bg-transparent hover:text-natural-grey',
                    activePageNumber === page && '!text-info bg-white'
                ])"
                outlined
                @click="handlePage(page)"
            >
              {{ page }}
            </TsButton>
          </template>
          <span v-if="props.type === 'report'" class="!bg-transparent !cursor-text flex items-center px-2"
          >
            Showing
            <span class="font-semibold">&nbsp;{{ props.offset * props.activePageNumber - props.offset + 1 }}&nbsp;</span>to
            <span class="font-semibold">&nbsp;{{ props.offset * props.activePageNumber }}&nbsp;</span> of
            <span class="font-semibold">&nbsp;{{ dataLength }}</span>
          </span
          >
          <TsButton
              class="flex items-center gap-1.5"
              :class="twMerge([
                'px-3',
                styleClasses.disabled
            ])"
              @click="handleNext"
              :disabled="activePageNumber === totalPages"
          >
            <span v-if="props.withText || props.textOnly">Next</span>
            <Icon v-if="!props.textOnly" name="iconoir:nav-arrow-right"/>
          </TsButton>
          <TsButton
              class="flex items-center gap-1.5"
              :class="twMerge([
                'px-3',
                styleClasses.disabled
            ])"
              v-if="props.type !== 'simple'"
              @click="handleLast"
              :disabled="activePageNumber === totalPages"
          >
            <span v-if="props.withText || props.textOnly">Last</span>
            <Icon v-if="!props.textOnly" name="iconoir:fast-arrow-right"/>
          </TsButton>
        </div>
        <div v-if="props.withInput" class="flex items-center gap-2">
          <TsSelectBox :options="pageNumbers"
                       v-model="model"
                       :class="twMerge([
                           'text-natural-grey dark:text-natural-light-grey rounded-md text-center',
                           'focus:outline-none focus:shadow-none',
                           'border-natural-light-grey dark:border-natural-grey',
                           'bg-transparent'
                       ])"
          />

          <TsInput
              appendClass="py-0 rounded-md"
              inputClass="w-5 py-2 text-center"
              v-model.number="model"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {twMerge} from "tailwind-merge";
import { computed } from 'vue';

const model = defineModel<number>();
const emit = defineEmits(["first", "prev", "change", "next", "last"]);

type Props = {
  dataLength?: number;
  offset?: number;
  activePageNumber?: number;
  type?: "normal" | "stepless" | "simple" | "extended" | "report";
  outlined?: boolean;
  withInput?: boolean;
  withText?:boolean;
  textOnly?:boolean;
  isTitle?: boolean;
  appendClass?: string;
};
const props = withDefaults(defineProps<Props>(), {
  type: "normal",
  activePageNumber: 1,
  offset: 10,
  withInput: false,
});

// Style classes
const styleClasses = {
  disabled: 'disabled:bg-transparent disabled:cursor-not-allowed disabled:text-natural-silver-grey dark:disabled:bg-transparent dark:disabled:text-natural-grey'
}


const totalPages = computed(() => Math.ceil(
    props.dataLength ? props.dataLength / props.offset : 0
))

const pageNumbers = computed(() => Array(totalPages.value)
    .fill(null)
    .map((_, i) => i + 1));
const firstPageNumber = computed(() => pageNumbers.value.at(0));
const lastPageNumber = computed(() => pageNumbers.value.at(-1));

function getVisiblePages(totalNumbersInPagination: number) {
  if (totalPages.value > 4) {
    // Calculate how many page numbers to show on each side of the current page number
    const leftAdjacentPageCount = Math.ceil(totalNumbersInPagination / 2);
    const rightAdjacentPageCount = Math.floor(totalNumbersInPagination / 2);

    // Determine the starting index for visible page numbers based on the current page number and left adjacent page count
    const startIndex = Math.max(
        0,
        props.activePageNumber - leftAdjacentPageCount
    );

    // Determine the ending index for visible page numbers based on the length of the pages array and the current page number
    const endIndex = Math.min(
        pageNumbers.value.length,
        props.activePageNumber + rightAdjacentPageCount
    );

    // Adjust the starting and ending indices if necessary to ensure that they fall within the bounds of the pages array
    const adjustedStartIndex =
        props.activePageNumber <= pageNumbers.value.length - leftAdjacentPageCount
            ? startIndex
            : startIndex -
            (rightAdjacentPageCount -
                (pageNumbers.value.length - props.activePageNumber));
    const adjustedEndIndex =
        props.activePageNumber > rightAdjacentPageCount
            ? endIndex
            : endIndex + (rightAdjacentPageCount + 1 - props.activePageNumber);

    // Return an array containing only the visible page numbers
    return pageNumbers.value.slice(adjustedStartIndex, adjustedEndIndex);
  } else {
    return pageNumbers.value;
  }
}

function handleExtendedVariant() {
  if (props.activePageNumber < 5) {
    return [...getVisiblePages(5).slice(0, -1), "...", totalPages.value];
  } else if (props.activePageNumber > totalPages.value - 3) {
    return [1, "...", ...getVisiblePages(5).slice(1)];
  } else {
    return [1, "...", ...getVisiblePages(3), "...", totalPages.value];
  }
}

const visiblePaginations = {
  normal: () => getVisiblePages(5),
  stepless: () => [props.activePageNumber, "/", totalPages.value],
  extended: () =>
  totalPages.value > 7 ? handleExtendedVariant() : getVisiblePages(5),
  simple: () => [],
  report: () => [],
}[props.type];

function handlePrev() {
  if (props.activePageNumber > 1) {
    emit("prev", props.activePageNumber - 1);
  }
}

function handleNext() {
  if (props.activePageNumber < totalPages.value) {
    emit("next", props.activePageNumber + 1);
  }
}

function handleFirst() {
  emit("first", firstPageNumber.value);
}

function handleLast() {
  emit("last", lastPageNumber.value);
}

function handlePage(page: string | number) {
  if (typeof page === "number") {
    emit("change", page);
  }
}

</script>
